<template>
    <SearchClientNew
        placeholder="Sök klient..."
        :options="clientsMaped"
        :disabled="isGlobalClientSelected"
        @on-selected="onSelected"
        :selected="selectedClient"
        style="width: 400px;"
        :drop-down-width="dropDownWidth"
        :size="size"
        :un-styled="unStyled"
        :required="required"
    />
</template>
<script>
export default {
    components: {
        SearchClientNew: () => import(/* webpackChunkName: "searchClientNew" */ "./searchClientNew.vue"),
    },

    props: {
        selected: {
            type: Object,
        },
        dropDownWidth: {
            type: String,
            default: "135%",
        },
        size: {
            type: String,
            default: "small",
        },
        unStyled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        excludeArchivedClients: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        isGlobalClientSelected(newValue, oldValue) {
            if (oldValue && !newValue) {
                this.changeTitle();
                this.$emit("on-selected", this.selectedClient);
            }
        },
    },

    data: function() {
        return {
            clientsData: [],
        };
    },

    async created() {
        this.clientsData = this.clientsList;
        this.clientSelectChange();
    },

    methods: {
        clientSelectChange() {
            if (this.isGlobalClientSelected) {
                var client = this.clientsData.find(cl => cl.value == this.selectedClient.value);
                this.changeTitle(client.name);
                this.$emit("on-selected", this.selectedClient);
            }
        },

        async onSelected(client) {
            if (client === "") {
                this.changeTitle();
            } else {
                var selectedClient = this.clientsData.find(cl => cl.value == client.value);
                this.changeTitle(selectedClient.name);
            }

            this.$emit("on-selected", client);
        },

        changeTitle(title = "") {
            if (title === "" || title === undefined) {
                document.title = this.$route.meta.title;
            }
            document.title = this.$route.meta.title + " - " + title;
        },
    },

    computed: {
        clientsMaped() {
            let clients = this.clientsData.map(item => ({
                nameShort: item.name,
                name: item.searchName,
                value: item.value,
                status: item.status,
                test: item.test,
                email: item.email,
                companyType: item.companyType,
            }));

            if (this.excludeArchivedClients) {
                const excludedStatuses = ["Inaktiverad", "Ombildat till AB", "Ej kund längre"];
                clients = clients.filter(item => !excludedStatuses.includes(item.status));
            }

            return clients;
        },
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },

        selectedClient() {
            return this.isGlobalClientSelected ? this.$store.state.topBar.selectedClient : this.selected;
        },

        clientsList() {
            return this.$store.state.clientList;
        },
    },
};
</script>
